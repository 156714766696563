// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subscriber-btn_notify-subscriber-btn__JUQXn{background-color:var(--ops-primary-blue) !important;width:100%;border-color:var(--ops-primary-blue) !important}", "",{"version":3,"sources":["webpack://./src/component/subscriber-btn/index.module.scss"],"names":[],"mappings":"AAEA,6CACE,mDAAA,CACA,UAAA,CACA,+CAAA","sourcesContent":["@import '../../assets/color-variable.scss';\n\n.notify-subscriber-btn {\n  background-color: var(--ops-primary-blue) !important;\n  width: 100%;\n  border-color: var(--ops-primary-blue) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notify-subscriber-btn": "subscriber-btn_notify-subscriber-btn__JUQXn"
};
export default ___CSS_LOADER_EXPORT___;
