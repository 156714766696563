import OktaAuth from '@okta/okta-auth-js'

export const NotificationOktaConfig = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  responseType: 'code',
  tokenManager: {
    expireEarlySeconds: 120,
    autoRenew: true,
    storageKey: 'sensing-okta-token',
  },
})
