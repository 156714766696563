// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-tile_home__j0HSe{display:flex;width:100vw;height:100vh;align-items:center;flex-direction:column}.notification-tile_home__j0HSe .notification-tile_notify-metric__MGDvZ{margin-top:4px}.notification-tile_metricbody__LxRuC>p{font-weight:500}.notification-tile_metricbody__LxRuC>p>span{color:var(--ops-tertiary-blue)}.notification-tile_notification-gray__2ubEW{color:var(--ops-gray-500)}.notification-tile_wrapper__Ig9yn{flex:1 1}", "",{"version":3,"sources":["webpack://./src/component/notification-tile/index.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,uEACE,cAAA,CAIF,uCACE,eAAA,CACA,4CACE,8BAAA,CAIN,4CACE,yBAAA,CAEF,kCACE,QAAA","sourcesContent":[".home {\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n  flex-direction: column;\n  .notify-metric {\n    margin-top: 4px;\n  }\n}\n.metricbody {\n  > p {\n    font-weight: 500;\n    > span {\n      color: var(--ops-tertiary-blue);\n    }\n  }\n}\n.notification-gray {\n  color: var(--ops-gray-500);\n}\n.wrapper {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": "notification-tile_home__j0HSe",
	"notify-metric": "notification-tile_notify-metric__MGDvZ",
	"metricbody": "notification-tile_metricbody__LxRuC",
	"notification-gray": "notification-tile_notification-gray__2ubEW",
	"wrapper": "notification-tile_wrapper__Ig9yn"
};
export default ___CSS_LOADER_EXPORT___;
