/* eslint-disable multiline-ternary */
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { LoginCallback } from '@okta/okta-react'
import AuthComponent from './component/auth-component'
import RequiredAuthComponent from './component/auth-component/required-auth'
import Home from './pages/home'
import Notification from './pages/notification'
import ApproverOAuth from './pages/approver'
import { useNavManager } from './hooks/use-nav-manager'
import { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

interface RouterListType {
  path: string
  element: JSX.Element
  protected: boolean
  initialCheckComponent?: JSX.Element
  key: number
  cb?: (props: any) => void
}
const queryClient = new QueryClient()
const NotificationRouter: React.FC = () => {
  const [notificationState, setNotificationState] = useState<any>({
    sideBarHide: true,
    mainHeaderHide: true
  })
  // LOGIC: used array of routes and loop instead of directly adding route component to return statement
  const routerList: RouterListType[] = [
    {
      key: parseInt(JSON.stringify(Math.random() * 100)),
      path: '/notification/approval/approve/:approverToken',
      protected: true,
      initialCheckComponent: (
        <>
          <RequiredAuthComponent />
        </>
      ),
      element: (
        <>
          <ApproverOAuth cb = { (props: { showMenu: any }) => {
            console.log('hi', props)
            if (props.showMenu) {
              setNotificationState({
                sideBarHide: false,
                mainHeaderHide: false
              })
            }
          } } isApproved={true}></ApproverOAuth>
        </>
      ),
    },
    {
      key: parseInt(JSON.stringify(Math.random() * 100)),
      path: '/notification/approval/deny/:deniedToken',
      protected: false,
      initialCheckComponent: (
        <>
          <RequiredAuthComponent />
        </>
      ),

      element: (
        <>
          <ApproverOAuth cb ={ (props: { showMenu: any }) => {
            if (props.showMenu) {
              setNotificationState({
                sideBarHide: false,
                mainHeaderHide: false
              })
            }
          } }isApproved={false}></ApproverOAuth>
        </>
      ),
    },
    {
      key: parseInt(JSON.stringify(Math.random() * 100)),
      path: '/',

      element: (
        <>
          <Notification renderedText={'This text is shown from base path'} />
        </>
      ),
      protected: true,
      initialCheckComponent: (
        <>
          <RequiredAuthComponent />
        </>
      ),
    },
    {
      key: parseInt(JSON.stringify(Math.random() * 100)),
      path: '/notification',
      element: (
        <>
          <Notification />
        </>
      ),
      protected: true,
      initialCheckComponent: (
        <>
          <RequiredAuthComponent />
        </>
      ),
    },
    {
      key: parseInt(JSON.stringify(Math.random() * 100)),
      path: '/notification/:notificationId',
      element: (
        <>
          {process.env.REACT_APP_NODE_ENV === ('development' || 'test') ? (
            <Home />
          ) : (
            <Notification />
          )}
        </>
      ),
      protected: true,
      initialCheckComponent: (
        <>
          <RequiredAuthComponent />
        </>
      ),
    },
    {
      key: parseInt(JSON.stringify(Math.random() * 100)),
      path: '/implicit/callback',
      element: <Navigate to="/notification" replace />,
      protected: true,
      initialCheckComponent: <LoginCallback />,
    },
  ]
  useNavManager(notificationState.mainHeaderHide, notificationState.mainHeaderHide, notificationState.sideBarHide)
  return (
    <BrowserRouter>
      <AuthComponent>
        <QueryClientProvider client={queryClient}>
        <Routes>
          {routerList.map((routerElement: RouterListType, i: number) => (
            <Route
              key={routerElement.key}
              {...(routerElement.protected
                ? {
                    cb: routerElement.cb,
                    element: routerElement.initialCheckComponent,
                    path: routerElement.path,
                    children: <Route path="" element={routerElement.element} />,
                  }
                : {
                    cb: routerElement.cb,
                    path: routerElement.path,
                    element: routerElement.element,
                  })}
            />
          ))}
        </Routes>
        </QueryClientProvider>
      </AuthComponent>
    </BrowserRouter>
  )
}
export default NotificationRouter
